var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container--tablet container container--md-50" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "expert_requested" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "v-layout",
              { staticClass: "simpleContainer", attrs: { wrap: "", row: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("span", { staticClass: "elementSubTitle" }, [
                    _vm._v("Rapport gegevens")
                  ]),
                  _c(
                    "div",
                    { staticClass: "elementPanel elementPanel--spaced pb-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "generalInfoBlock",
                              attrs: { xs12: "", md4: "" }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "mb-2": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "infoPanelColumn__fadedText"
                                            },
                                            [_vm._v("Dossiernummer:")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportModel.case_number
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "mb-2": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "infoPanelColumn__fadedText"
                                            },
                                            [_vm._v("Naam aanvrager:")]
                                          )
                                        ]
                                      ),
                                      _vm.reportModel.applicant
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "infoPanelColumn",
                                              attrs: { sm6: "" }
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.reportModel.applicant
                                                      .name
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "mb-2": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "infoPanelColumn__fadedText"
                                            },
                                            [_vm._v("Bouwjaar:")]
                                          )
                                        ]
                                      ),
                                      _vm.generalKeys.Bouwjaar &&
                                      _vm.generalKeys.Bouwjaar.value
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "infoPanelColumn",
                                              attrs: { sm6: "" }
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.generalKeys.Bouwjaar
                                                      .value
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "mb-2": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "infoPanelColumn__fadedText"
                                            },
                                            [_vm._v("Adres:")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "infoPanelColumn",
                                          attrs: { sm6: "" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportModel.address
                                                  ? _vm.reportModel.address
                                                      .street
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.reportModel.address
                                                  ? _vm.reportModel.address
                                                      .number
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.reportModel.address
                                                  ? _vm.reportModel.address
                                                      .number_add
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportModel.address
                                                  ? _vm.reportModel.address
                                                      .postcode
                                                  : ""
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.reportModel.address
                                                  ? _vm.reportModel.address.city
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "mt-4" }, [
                                _vm.googleMaps && _vm.googleMaps.length
                                  ? _c("iframe", {
                                      staticStyle: { border: "0" },
                                      attrs: {
                                        width: "100%",
                                        height: "100%",
                                        frameborder: "0",
                                        src:
                                          "https://www.google.com/maps/embed/v1/place?key=AIzaSyD9Sc8IeggEfrqhwDvY5h8Dt3b-w0aomQo&q=" +
                                          _vm.googleMaps,
                                        allowfullscreen: ""
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md8: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "fill-height": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { "pb-0": "", "pl-3": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  name: "comment",
                                                  label: "Reactie"
                                                },
                                                model: {
                                                  value: _vm.comment,
                                                  callback: function($$v) {
                                                    _vm.comment = $$v
                                                  },
                                                  expression: "comment"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "warning",
                                  small: "true",
                                  icon: "cancel"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.selectedUitvraagResponse = "reject"
                                    _vm.openDialog()
                                  }
                                }
                              },
                              [_vm._v(" Afslaan ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "success",
                                  small: "true",
                                  disabled: !_vm.comment.length,
                                  icon: "check_circle"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.selectedUitvraagResponse = "approve"
                                    _vm.openDialog()
                                  }
                                }
                              },
                              [_vm._v(" Beantwoorden ")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2802828082
              )
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }