import { Component, Vue } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { DialogType, Options } from '@/components/mi-dialog/MiDialog';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';

@Component<Uitvraag>({})
export default class Uitvraag extends Vue {
  public $pageTitle = 'Uitvraag IMG';

  protected reportModel: ReportModel = new ReportModel();

  protected googleMaps = '';

  protected isLoading = true;

  protected comment = '';

  protected generalKeys: GeneralKey = {
    SchadeadresStraat: GeneralKeys.SchadeadresStraat,
    SchadeadresHuisnummer: GeneralKeys.SchadeadresHuisnummer,
    SchadeadresHuisnummerToevoeging: GeneralKeys.SchadeadresHuisnummerToevoeging,
    SchadeadresPlaats: GeneralKeys.SchadeadresPlaats,
    SchadeadresPostcode: GeneralKeys.SchadeadresPostcode,
    BouwJaar: GeneralKeys.BouwJaar,
  };

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  // approval bar
  protected selectedUitvraagResponse: string | null = null;

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.isLoading = true;

    this.reportModel
      .include(['applicant'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;
        this.isLoading = false;

        if (! this.$store.state.Auth.external_user) {
          this.initBreadcrumb();
        }

        this.initGoogleMaps();
        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Uitvraag' },
        ],
      });
  }

  protected initGoogleMaps() {
    if (this.reportModel.address
      && this.reportModel.address.street
      && this.reportModel.address.number
      && this.reportModel.address.postcode
      && this.reportModel.address.city
    ) {
      this.googleMaps = encodeURI(`${this.reportModel.address.street} ${this.reportModel.address.number} ${this.reportModel.address.number_add ? this.reportModel.address.number_add : ''},  ${this.reportModel.address.postcode} ${this.reportModel.address.city}`);
    }
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected get uitvraagDialogType(): DialogType {
    return this.selectedUitvraagResponse === 'approve' ? 'success' : 'warning';
  }

  protected get uitvraagDialogHeader(): string {
    return this.selectedUitvraagResponse === 'approve' ? 'Uitvraag beantwoorden' : 'Uitvraag afslaan';
  }

  protected get uitvraagButtonText(): string {
    return this.selectedUitvraagResponse === 'approve' ? 'Versturen' : 'Afslaan';
  }

  protected get uitvraagDialogText(): string {
    return this.selectedUitvraagResponse === 'approve'
      ? 'Verstuur reactie naar Teruggezet naar Werkvoorbereiding.'
      : 'Dit dossier past niet bij ons.';
  }

  protected openDialog() {
    this.$store.dispatch('openDialog', this.uitvraagDialogOptions);
  }

  protected updateReport() {
    if (! this.reportModel) {
      return;
    }

    const comment = this.comment.length ? `- ${this.comment}` : '';

    const payload = {
      status: 'werkvoorbereiding_rejected',
      status_comment: this.selectedUitvraagResponse === 'approve' ? this.comment : `Dit dossier past niet bij ons ${comment}`,
    };

    this.reportModel
      .update(payload)
      .then(() => {
        this.selectedUitvraagResponse = null;
        this.$router.push(`/reports/${this.reportModel ? this.reportModel.uuid : ''}`);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get uitvraagDialogOptions(): Options {
    return {
      title: this.uitvraagDialogHeader,
      text: this.uitvraagDialogText,
      type: this.uitvraagDialogType,
      buttons: {
        confirm: {
          text: this.uitvraagButtonText,
          color: this.uitvraagDialogType,
          action: () => {
            this.updateReport();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedUitvraagResponse = null;
          },
        },
      },
    };
  }
}
